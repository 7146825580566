<script setup lang="ts">
import type { VariantProps } from 'cva'

import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'

const {
  variant = 'default',
  size = 'default',
  isDisabled = false,
  isLoading = false,
} = defineProps<Props>()

const emits = defineEmits<{
  click: []
}>()

const variantOptions = {
  default: 'border-2 border-neutral-black bg-neutral-black text-white',
  slider: 'border-b-2 border-transparent font-semibold transition-all text-neutral-white text-[1.6rem]',
  secondary: 'border border-neutral-white bg-neutral-white px-2.5rem py-1rem text-center text-1.3rem text-neutral-black transition duration-300 hover:bg-transparent hover:text-neutral-white',

}

const sizeOptions = {
  icon: 'p-3 w-10',
  default: 'px-6 py-2',
  sm: 'rounded px-3',
  lg: 'rounded px-8 py-3 text-[1.4rem]',
  full: 'w-full py-2 px-6',
}

const buttonVariants = cva(
  {

    base: 'text-center text-[1.3rem] transition duration-300',
    variants: {
      variant: variantOptions,
      size: sizeOptions,
      isActive: {
        true: '!border-starline-primary shadow-button',
        false: null,
      },
      isRounded: {
        true: 'rounded-full',
        false: '',
      },
    },
    compoundVariants: [
      {
        isActive: true,
        variant: 'slider',
        class: 'border-b-2 border-starline-primary shadow-none',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

 type ButtonProps = VariantProps<typeof buttonVariants>

interface Props {
  isDisabled?: boolean
  isLoading?: boolean
  isRounded?: boolean
  isActive?: ButtonProps['isActive']
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
}

function handleClick(): void {
  if (!isDisabled && !isLoading)
    emits('click')
}

const typeElement = computed(() => {
  return 'button'
})

const classes = useAttrs().class as string
</script>

<template>
  <Component
    :is="typeElement"
    :class="twMerge(buttonVariants({ variant, size, isRounded, isActive }), classes)" @click="handleClick"
  >
    <slot />
  </Component>
</template>
