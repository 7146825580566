<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue'

interface SingleButton {
  block_simple_specification_button_id: {
    id: string
    image: DirectusFile
    translations: DirectusTranslation<{
      text: string
      description: string
    }>
  }
}
const props = defineProps<{
  data: {
    translations: DirectusTranslation<{
      title: string
      section_title: string
    }>
    buttons: SingleButton[]
  }
}>()

const translatedTitle = computed(() => dt(props.data.translations)?.title)
const translatedDescription = computed(() => dt(props.data.translations)?.section_title)
const buttons = computed(() => props.data.buttons.map(button => ({
  id: button.block_simple_specification_button_id.id,
  text: dt(button.block_simple_specification_button_id.translations)?.text,
  description: dt(button.block_simple_specification_button_id.translations)?.description,
  image: button.block_simple_specification_button_id.image,
})))

const activeButtonId = ref(buttons.value[0].id)
const activeButton = computed(() => buttons.value.find(button => button.id === activeButtonId.value))

const isButtonActive = (id: string) => activeButtonId.value === id

const images = computed(() => buttons.value.map(button => button.image))

const [container, slider] = useKeenSlider({
  loop: true,
  renderMode: 'performance',
  drag: true,
  slides: {
    perView: 1,
  },
})

function nextSlide() {
  slider?.value?.next()
}
function prevSlide() {
  slider?.value?.prev()
}

function onActiveButtonClick(id: string) {
  const buttonIndex = buttons.value.findIndex(button => button.id === id)
  slider.value?.moveToIdx(buttonIndex)
  activeButtonId.value = id
}
</script>

<template>
  <BlockBase is-light class="bg-starline-secondary py-15rem block-light:bg-neutral-white block-light:text-neutral-black">
    <Container class="grid grid-cols-1 w-full gap-10rem lg:grid-cols-2">
      <!-- SLIDER -->
      <div
        v-if="images.length" ref="container" class="keen-slider order-last my-auto h-36rem w-full md:order-none lg:h-65rem"
      >
        <div v-for="image, index in images" :key="index" class="keen-slider__slide size-full">
          <NuxtImg
            v-if="image"
            loading="lazy"
            provider="directus"
            :src="image.id"
            :alt="image.description"
            height="650" width="650"
            class="h-full w-full object-cover"
          />
        </div>

        <div v-if="images.length > 1" class="absolute bottom-0 right-0 flex items-center justify-center bg-starline-secondary lg:h-10rem lg:w-15rem block-light:bg-neutral-white">
          <button class="h-8rem w-6rem flex items-center justify-center transition lg:h-10rem lg:w-7.5rem hover:bg-neutral-black/10 block-light:hover:bg-starline-secondary/10" @click="prevSlide">
            <IconSmallArrow class="h-2rem w-2rem" />
          </button>
          <button class="h-8rem w-6rem flex items-center justify-center transition lg:h-10rem lg:w-7.5rem hover:bg-starline-secondary/10" @click="nextSlide">
            <IconSmallArrow class="h-2rem w-2rem rotate-180" />
          </button>
        </div>
      </div>

      <!-- Content -->
      <div
        class="my-auto h-full w-full"
      >
        <div class="h-full flex flex-col gap-16">
          <div>
            <h1 class="mb-4rem text-balance text-4rem font-title md:text-7.6rem">
              {{ translatedTitle }}
            </h1>
            <TextWithAfterLine v-if="translatedDescription" :text="translatedDescription" />
          </div>

          <div class="flex flex-wrap gap-0.8rem">
            <AppButton v-for="button, index in buttons" :key="index" :is-active="isButtonActive(button.id)" size="lg" class="w-full tracking-4px lg:w-auto" @click="onActiveButtonClick(button.id)">
              {{ button.text?.toLocaleUpperCase() }}
            </AppButton>
          </div>
          <div class="flex-1">
            <p v-if="activeButton" class="whitespace-pre-line text-[1.4rem]" v-html="activeButton.description" />
          </div>
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style scoped>
</style>
